#contato{
	display: flex;
	flex-direction: column;	
	align-items: center;
	width: 100%;
	min-height: 100%;
}

#contato-menu{
	height:170px;
	display: flex;
	flex-direction: row;	
	justify-content: center;
	align-items: flex-end;
	width: var(--largura-pagina);
}

#footer-contato{
	position: relative;
	bottom: -10px;
	margin:0;
	padding: 0;
}

#contato-conteudo{
	min-height: 460px;
	height: auto;
	display: flex;
	flex-direction: row;	
	justify-content: space-between;
	align-items: flex-start;
	width: 780px;
	margin-top: 70px;
	margin-bottom: 90px;
}

#contato-direita{
	width: 500px;
	height: 365px;
}

#contato-esquerda{
	display: flex;
	flex-direction: column;	
	justify-content: space-between;
	align-items: flex-start;
	min-width: 350px;
	width: 40%;	
	margin-right: 30px;
}

#contato-esquerda form{
	min-width: 270px;
	width: 100%;
}

#contato-titulo{
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 42px;
	line-height: 42px;
	letter-spacing: -0.403846px;
	color: var(--azul-escuro);
	padding: 0;
	width: 400px;
}

#contato-texto{
	color: var(--cor-fonte);
	font-size: var(--font-tamanho);
	padding: 0;
	margin: 0;
	margin-bottom: 15px;
	width: 300px;
}

.contato-end{
	color: var(--cor-fonte);
	font-size: var(--font-tamanho);
	padding: 0;
	margin: 0;
	
}

#contato-tel{
	color: var(--cor-fonte);
	font-size: calc(var(--font-tamanho)+2);
	font-weight: bold;
	margin:0;
}

.margintop{
	margin-top: 20px;
}
/*
#icone-whats-contato{
	font-size: 18px;
	margin-right: 3px;
}*/

#contato-whats{
	color: var(--cor-fonte);
	font-size: var(--font-tamanho);
	font-weight: bold;
	margin-bottom:10px;
	width: 300px;
}

#contato-form{
	display: flex;
	flex-direction: column;	
	justify-content: space-between;
	align-items: space-between;
	margin-top: 20px;
	width: 100%;
}

#contato-form label{
	font-family: Nunito !important;
	font-size: 13px !important;
	margin-bottom: 0px !important;
}

.campos-contato{
	border: 1px solid var(--cinza-contorno);
	border-radius: 3px !important;
	margin-bottom: 7px !important;
	padding: 0px 5px 5px 10px !important;
	font-family: Nunito !important;
	font-size: 13px !important;
	line-height: 20px !important;
	width: 100%;
	min-width: 200px;
}

#texto-nome,#texto-email{
	min-height: 32px !important;
	line-height: 32px !important;
}

#texto-email::placeholder{ 
	line-height: 32px !important;
}

#texto-nome::placeholder{ 
	line-height: 32px !important;
}

#submit-btn{
	display: flex;
	flex-direction: row;	
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
}

#alerta-sucesso{
	font-size: 13px !important;
	padding-top: 8px;  
	height: 40px;
	display: none;
}

#alerta-erro{
	font-size: 13px !important;
	padding-top: 8px; 
	height: 40px;
	display: none;
}

#alerta-invalido{
	font-size: 13px !important;
	padding-top: 8px; 
	height: 40px;
	display: none;
}

textarea{
	resize: none;
}

#footer-contato:hover{
	cursor: pointer;
}

#maps{
	width: 100%;
	height: 100%;
}

.link{
	color: var(--azul-claro) !important;
}

@media only screen and (max-width:1024px) {
	
}

@media only screen and (max-width:768px) {


	#contato{
		max-width: 450px;
		margin:0px auto;
	}

	#contato-conteudo{
		height: 720px;
		display: flex;
		flex-direction: column;	
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 0px;
		margin-bottom: 200px;
		position: relative;
	}

	#contato-texto,.contato-end{
		width: 260px;
	}

	#contato-tel{
		width: 260px;
		margin-bottom: 7px;
	}

	#contato-menu{
		width: 100%;
		line-height: 30px !important;
	}

	#contato-titulo{
		font-size: 27px;
		line-height: 32px;
		min-width: 250px;
		text-align: center;
	}

	#contato-esquerda{
		width: 100%;
		padding: 0px 40px 0px 43px;
		min-width: 260px;
		min-height: 460px;
		margin-bottom:20px;
	}

	.campos-contato{
		width: 270px;
		min-width: 260px;
	}

	#contato-direita{
		width: 100%;
		min-height: 380px;
		height: 100%;
		padding: 0px 20px 0 20px;
	}

	#alerta-invalido{
		height: 60px;
		display: none;
	}

	#footer-contato{
		
	}

	#contato-menu{
		height:120px;
		justify-content: center;
		align-items: flex-end;
		margin-bottom: 40px;
	}

}

@media only screen and (max-width:414px) {	

}