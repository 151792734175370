
/*----menu----*/
.menu{
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;	
	justify-content: space-between;
	align-items: center;
	width: 780px;
	margin: 40px auto;
}

.menu-nav{	
	width: 294px;
	display: flex;	
	align-items: center;
	flex-direction: row;	
}

/*.menu-nav-c{	
	display: flex;	
	align-items: center;
	flex-direction: row;	
	z-index: 2;
}*/

.menu-ul{
	list-style-type: none;
	padding: 0;
	margin: 0;	
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;	
	justify-content: space-between;
}

/*.menu-ul-c{
	display: none;
}*/

.menu-ul-li{
	font-family: Nunito !important;
	font-size: 14px;
	line-height: 22px;
	display: flex;
}

.menu-ul-li a{
	color: var(--cor-fonte) !important;
}

.menu-ul-li a:hover {
	cursor: pointer;
}

.menu-logo{
	margin: 0 !important;	
	padding: 0 !important;
	z-index: 2;
}

#logo{
	margin:0 !important;
	padding: 0 !important;
}

#logo-c{
	display: none;
	padding: 20px;
}

#background-menu-c{
	background-color: var(--azul-escuro);
	position: fixed;
	top:0px;
	right: 0px;
	width: 0px;
	height: 0px;
	transition: width 0.5s, height 0.5s;
}

#menu-c-header{
	display: flex;
	flex-direction: row;	
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
	height: 100px; 
	opacity: 0;
	transition: opacity 0.5s;
}

#menu-c-body{
	display: flex;
	flex-direction: column;	
	align-items: space-between;
	width: 100%;
	height: 280px;	
	opacity: 0;
	transition: opacity 0.5s;
	margin-left: 30px;
}

#menu-c{
	display: none;
}

#btn-fechar{
	padding-right: 20px;
	display: none;
	color:white;
}

#menu-items-c{
	margin-left: 15px;
}

.menu-ul-li-c a{
	color: white !important;
	margin-right: 15px;
}

.menu-ul-li-c{
	font-family: Nunito;
	font-size: 17px;
	line-height: 40px;
	display: flex;

}

.menu-ul-li-c:first-child{
	font-family: Nunito;
	font-size: 19px;
	line-height: 40px;
	font-weight: bold;
	display: flex;
}

@media only screen and (max-width:768px) {

	.menu-nav{
		display: none;
	}

	.menu{
		display: flex;
		flex-direction: row;	
		justify-content: space-between;
		align-items: center;
		width: 80%;
		margin: 20px auto 75px;
	}

	#logo img{
		width:165px;
		height:50px;
	}

	#logo-c{
		display: block;
		z-index: 1;
	}

	#btn-fechar{
		display: flex;
		align-self: center;
		margin-top: -20px;
	}

	#menu-c{
		display: flex;
		z-index: 10;
	}

/*
	#background-menu-c{
		z-index: 5;
	}

	#menu-c-body{
		z-index: 5;
	}*/

	
}
