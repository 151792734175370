#suporte{
	display: flex;
	flex-direction: column;	
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

#painel-familia-suporte{
	background-image:url('../assets/images/suporte/familia-suporte.png');
	width: var(--largura-pagina);
	height: 330px;
}

.tudo-suporte{
	display: flex;
	flex-direction: column;	
	align-items: center;
	justify-content: center;
	width: var(--largura-texto);
	height: 100%;
	position: relative;
	top:-175px;
	margin-bottom: -110px;
}

#titulo-suporte{
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 42px;
	line-height: 42px;
	letter-spacing: -0.403846px;
	color: var(--azul-escuro);
}

#monitoramento-24h-suporte{
}

#monitoramento-suporte{
	font-family: TTSupermolotNeue-ExpandedBoldItalic;
	font-size: 44px;
	letter-spacing: -0.403846px;
	padding-left: 23px;
	color: var(--azul-escuro);
}

#vintequartoh-suporte{
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 200px;
	line-height: 120px;
	color: var(--azul-escuro);
	letter-spacing: -2px;
}

#conteudo-suporte{
	display: flex;
	flex-direction: row;	
	justify-content: space-between;
	align-items: flex-start;
	width: 780px;
	height: 215px;
	margin:220px auto 0;
}

#texto-suporte{
	display: flex;
	flex-direction: column;	
	align-items: flex-start;
	margin-left: 40px;
	justify-content: flex-start;
	width: 50%;
	font-size: var(--font-tamanho);
}

#texto-suporte-texto{
	margin-bottom: 30px;
}

#imagens-suporte{
	display: flex;
	flex-direction: column;	
	justify-content: space-around;
	width: 50%;
	padding-left: 20px;
}

.orcamento{
	z-index: 2;
}

#texto-imagem{
	font-family: Nunito;
	font-style: normal;
	font-weight: 800;
	font-size: 22px;
	letter-spacing: -0.692308px;	
	color: var(--azul-escuro);
	padding-left: 40px;
}

#item-suporte{
	display: flex;
	flex-direction: row;	
	justify-content: flex-start;
	align-items: baseline;
	margin-bottom: 15px;
}


@media only screen and (max-width:1480px) {
	
}

@media only screen and (max-width:768px) {

	#suporte{
		max-width: 450px;
		margin: 0px auto;
		margin-bottom: -70px;
	}

	#rodape-suporte{
		width:100%;
	}

	#monitoramento-24h-suporte{
		position: relative;
		top: -40px;		
	}

	.tudo-suporte{
		width: 100%;
		top:-135px;
	}

	#painel-familia-suporte{
		background-image:url('../assets/images/suporte/familia-suporte-c.png');
		background-repeat: round;
		background-size: cover;
		width: 100%;
		min-height: 330px;
		height: auto;
	}

	#conteudo-suporte{
		display: flex;
		flex-direction: column;	
		justify-content:center;
		align-items: center;
		width: 95%;
		height: 450px;
		margin-top: 150px;
	}

	#monitoramento-suporte{
		font-size: 26px;
		padding-left: 10px;
	}

	#vintequartoh-suporte{
		font-size: 120px;
		line-height: 50px;
	}

	#imagens-suporte{
		position: relative;
		top:-30px;
		width: 100%;
		padding:0px 20px 0px 25px;
	}

	#item-suporte{
		display: flex;
		flex-direction: row;	
		justify-content: flex-start;
		align-items: baseline;
		width: 100%;
		margin-bottom: 0px;
	}

	#texto-suporte{
		width: 100%;
		padding:0px 20px 0px 20px;
		flex-direction: column;	
		justify-content: flex-start;
		align-items: flex-start;
		margin-left: 0px;
	/*margin-bottom: 15px;*/
	}

	#texto-imagem{
		font-size: 18px;
		padding-left: 20px;
	}

	#titulo-suporte{
		font-size: 29px;
		line-height: 32px;
		min-width: 250px;
		text-align: center;
	}


}

@media only screen and (max-width:414px) {	
}