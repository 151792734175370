:root {
	--azul-escuro: #1B4488;
	--azul-claro: #2E83C4;
	--azul-claro-hover:#266CA1;
	--verde-claro: #2EC45C;
	--verde-claro-hover:#1EAA49;
	--cor-fonte:#303030;
	--cinza-claro:#F3F6FC;
	--cinza:#F8F8F8;
	--cinza-contorno: #D6D6D6;
	--font-tamanho: 14px;
	--largura-pagina: 1280px;
	--largura-texto: 780px;
}


@font-face{
	font-family: "Nunito";
	src:url(fontes/Nunito-Regular.ttf);
}

@font-face{
	font-family: "Nunito-Bold";
	src:url(fontes/Nunito-Bold.ttf);
}

@font-face{
	font-family: "Nunito-ExtraBold";
	src:url(fontes/Nunito-ExtraBold.ttf);
}

@font-face{
	font-family: "TTSupermolotNeue-ExpandedExtraBoldItalic";
	src:url(fontes/TTSupermolotNeue-ExpandedExtraBoldItalic.otf);
}

@font-face{
	font-family: "TTSupermolotNeueExtraBold";
	src:url(fontes/TTSupermolotNeue-ExtraBold.otf);
}

@font-face{
	font-family: "TTSupermolotNeue-ExpandedBoldItalic";
	src:url(fontes/TTSupermolotNeue-ExpandedBoldItalic.otf);
}


@import "ember-cli-bootstrap-4/bootstrap";
@import "contato";
@import "inicio";
@import "menu";
@import "servicos";
@import "suporte";
@import "historia";
@import "rodape";

* {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   margin: 0;
   padding:0;
}

html{
	width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    min-height: 100% !important;
}

img{
	max-width:100%;
	height:auto;
}

body{
	font-family: "Nunito" !important;
	height: 100%;
	width: 100%;
	min-height: 100%;
	min-width: 100%;
}

a {
	color:var(--cor-fonte);
	text-decoration:none;
}

a:hover {
	text-decoration:none;
}

a:active{
	text-decoration:none;
}

p,label{
	color: var(--cor-fonte);
}

#tudo{
	display: flex;
	flex-direction: column;	
	justify-content: center;
	align-items: center;
}

.botao{
	border-radius: 3px;
	border:none;
	color: white;
	font-size: 14px;
	height: 40px;
	width: 220px;
	z-index: 1;
}

.botao:hover{
	cursor: pointer;
}

.azul{
	background-color: var(--azul-claro);
}

.verde{
	background-color: var(--verde-claro);
}

.azul:hover{
	background-color: var(--azul-claro-hover);
}

.azul:active{
	background-color: var(--azul-claro);
}

.verde:hover{
	background-color: var(--verde-claro-hover);
}

.verde:active{
	background-color: var(--verde-claro);
}

.linha-azul{
	background-color: var(--azul-escuro);
	height: 10px;
	width: 100%;
	margin: 0;
	padding: 0;
}

.divisao{
	width: 100%;
	height: 1.5px;
	margin: 120px 0 0 0;
	background-color: lightgrey;
}

#inicio{
}

#historia{
}

#suporte{
}

#servicos{
}

#contato{
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}

@media only screen and (max-width:1480px) {
	
}

@media only screen and (max-width:768px) {

	#tudo{
		min-width: 100%;
	}

}

@media only screen and (max-width:414px) {
	
}