.footer{
	width: var(--largura-pagina);
	position: relative;
	bottom: 0;
	height: 322px;
}

.rodape-conteudo{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;	
	margin:0;
	padding:0;
}

.rodape-titulo{	
	font-family: Nunito-ExtraBold;
	position: relative;
	top:80px;
	font-style: normal;
	font-weight: 800;
	font-size: 44px;
	line-height: 44px;
	color: var(--cor-fonte);
	width: 300px;
}

.rodape-textos{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	top:80px;
	margin:0;
	padding:0;
}

.telefone,.rua,.bairro{
	color: var(--cor-fonte);
	margin-bottom: 7px;
	font-size: 14px;
}

.telefone{
	font-weight: bold;
}

#rua-maps{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#maps-icon{
	margin-left: 10px;
}

#maps-icon:hover{
	cursor: pointer;
}

@media only screen and (max-width:1480px) {
	
}

@media only screen and (max-width:768px) {
	.footer{
		width: 100%;
		position: relative;
		bottom: 0;
		height: 372px;
		margin:0;
		padding: 0;
	}

	.rodape-conteudo{
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;	
	}

	.rodape-titulo{	
		position: relative;
		top:50px;
		font-size: 40px;
		line-height: 40px;	
		width: 220px;
	}

	.rodape-textos{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		top:50px;
		margin:0;
		padding:0;
	}

}


@media only screen and (max-width:414px) {	
}