#historia{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;	
	align-items: center;
	width: 100%;
	height: 100%;
}

#historia-menu{
	height:190px;
	display: flex;
	flex-direction: row;	
	justify-content: center;
	align-items: flex-end;
	width: var(--largura-pagina);
	margin-bottom:90px; 
}

#conteudo-historia{	
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;	
	width: var(--largura-texto);
	height: 100%;
	align-items: space-between;	
	justify-content: space-between;
	z-index: 0;
}

#conteudo-hist-textos{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;	
	width: 50%;
	height: 100%;
	align-items: flex-start;	
}

#conteudo-hist-fotos{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;	
	align-items: center;
	width: 45%;
	height: 100%;
	margin-bottom: -200px;	
}

#nossa-historia,#nossos-valores,#social{
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 42px;
	line-height: 42px;
	letter-spacing: -0.403846px;
	color: var(--azul-escuro);
	padding: 0 20px 0 20px;
	margin-bottom: 30px;
}

#nossa-historia-texto,#nossos-valores-texto,#social-texto{
	font-size: var(--font-tamanho);
	line-height: 22px;
	letter-spacing: -0.692308px;
	width:440px;
	padding: 0 20px 0 20px;
	margin-bottom:80px;
}

#hist-segunda{
	position: relative;
	top:-180px;
	left:85px;
}

#hist-terceira{
	position: relative;
	top:-280px;
}

#link-saibamais{
	color: var(--azul-claro-hover) !important;
}

#link-saibamais:hover{
	color: var(--azul-claro) !important;
}

#link-saibamais:active{
	color: var(--azul-claro-hover) !important;
}

@media only screen and (max-width:1480px) {
	
}

@media only screen and (max-width:768px) {
	#historia,#conteudo-historia{
		max-width: 450px;
		margin:0px auto;
		margin-bottom: -50px;
	}

	#historia-menu{
		height:125px;
		display: flex;
		flex-direction: row;	
		justify-content: center;
		align-items: flex-end;
		max-width: 100%;
		margin-bottom:20px; 
	}

	#background-menu-historia{
		width: 100%;
		height:110px;
		margin: 0;
		padding: 0;
		z-index: 0;
	}
	#conteudo-historia{
		flex-direction: column;	
		width: 100% !important;
		height: 100%;
		align-items: space-between;	
		position: relative;
		top:20px;
		z-index: -1 !important;
		margin-bottom: 50px;
	}
	#conteudo-hist-textos{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;	
		width: 100%;
		height: 100%;
		align-items: flex-start;
		padding:10px;
		margin:0;
	}

	#conteudo-hist-fotos{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;	
		align-items: flex-start;
		width: 100%;
		height: 100%;
		padding:10px;
		margin:0;
		margin-bottom: -180px;
	}
	#hist-segunda{
		position: relative;
		left:10px;
	}

	#nossos-valores,#social{
		font-size: 25px;
		line-height: 30px;
	}

	#nossa-historia{
		font-size: 27px;
		line-height: 30px;
	}

	#nossa-historia-texto,#nossos-valores-texto,#social-texto{
		font-size: var(--font-tamanho);
		line-height: 22px;
		width:100%;
		padding-bottom: 35px;
		margin:0px;
	}

	#rodape-historia{
		width:100%;
	}

}

@media only screen and (max-width:414px) {	
}