#servicos{
	display: flex;
	flex-direction: column;	
	align-items: center;
	width: 100%;
	height: 100%;
}

#tudo-servicos{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;	
	justify-content: center;
	align-items: center;
	position: relative;
	top:-235px;
	z-index: 0;
	margin-bottom: -300px;
}

#painel-servicos{
	background-image:url('../assets/images/servicos/servicos.png');
	width: var(--largura-pagina);
	height: 400px;
}

.titulo,.titulo-serv{
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 42px;
	line-height: 42px;/* identical to box height, or 100% */
	color: var(--azul-escuro);
	padding: 0;
	position: relative;
	z-index: 3;
}

.cartao-servicos{
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;	
	justify-content: space-between;	
	z-index: 3;
	width: var(--largura-texto);
	margin-bottom: 60px;
}

.cartao-conteudo{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;	
	align-items: flex-start;
	height: 278px;
	width: 380px;
}

.cartao-texto{
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: var(--font-tamanho);
	line-height: 22px;
	letter-spacing: -0.692308px;	
	width: 380px;
	margin:0;
	padding: 0;
}

#cartao-monitoramento{
	margin-top: 120px;
}

#cartao-cerca{
	margin-bottom: 120px;
}

#monitoramento-24h-servicos{
	margin-bottom: 20px;
}

#monitoramento-servicos{
	font-family: TTSupermolotNeue-ExpandedBoldItalic;
	font-size: 20px;
	letter-spacing: -0.403846px;
	padding-left: 10px;
	color: var(--azul-escuro);
}

#vintequartoh-servicos{
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 90px;
	line-height: 30px;/* identical to box height, or 100% */
	color: var(--azul-escuro);
	letter-spacing: -2px;
}

#cerca-c,#alarme-c{
	display: none;
}


@media only screen and (max-width:1480px) {
	
}

@media only screen and (max-width:768px) {

	#servicos{
		max-width: 450px;
		margin: 0px auto;
		margin-bottom: -100px;
	}

	#painel-servicos{
		background-image:url('../assets/images/servicos/servicos-c.png');
		background-repeat: round;
		background-size: cover;
		width: 100%;
		min-height: 293px;
		height: auto;
	}

	.cartao-conteudo{
		height: 100%;
		width: 100%;
		margin-bottom: 100px;
	}
	.cartao-servicos{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;	
		justify-content: space-between;	
		width: 100%;	
		margin:0;
		padding:0px 20px 0px 20px;
		position: relative;
		top:-50px;
		margin-bottom: 0px;
	}

	/*#cartao-alarme,#cartao-servicos,#cartao-cerca{
		position: relative;
		top:-150px;		
	}*/

	.cartao-texto{
		font-family: Nunito;
		font-style: normal;
		font-weight: normal;
		font-size: var(--font-tamanho);
		line-height: 22px;
		letter-spacing: -0.692308px;	
		width: 100%;
		margin:0;
		padding: 0;
	}

	#cerca,#alarme{
		display: none;
	}
	
	#tudo-servicos{
		position: relative;
		top:-180px;
		width: 95%;
		margin-bottom: -160px;
	}

	.titulo{
		font-size: 27px;
		line-height: 32px;
		min-width: 250px;
	}

	.titulo-serv{
		font-size: 25px;
		line-height: 25px;
	}

	#cartao-cerca{
		margin-bottom: -80px;
	}

	#cartao-monitoramento{
		margin-top: 100px;
		margin-bottom: 0px;
	}

	#cartao-alarme{
		margin-bottom: 0px;
	}
	#rodape-servicos{
		width:100%;
	}

	#cerca-c,#alarme-c,#cftv-c,#monitoramento-c{
		width: 100%;
		display: flex;
		height: auto;
	}

	#cerca-c img,#alarme-c img,#cftv-c img,#monitoramento-c img{
		width: 100%;
		background-repeat: round;
		height: auto;
		min-height: 180px;
	}
}

@media only screen and (max-width:414px) {	
}