#inicio{
	display: flex;
	flex-direction: column;	
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-bottom: 50px;
}

#painel-familia{
	background-image:url('../assets/images/inicio/familia.jpg');
	width: var(--largura-pagina);
	height: 780px;
}

#painel-familia-botao{
	position: relative;
	left:220px;
	width: 300px;
}

#slogan-inicio{
	position: relative;
	left:200px;
	line-height: 54px;
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 52px;
	letter-spacing: -2px;
	padding-left: 15px;
	color: var(--cor-fonte);
	width: 410px;
}

#figura-orcamento{
	
}

#figura-orcamento-c{
	display: none;
}

#monitoramento-inicio{
	font-family: TTSupermolotNeue-ExpandedBoldItalic;
	font-size: 31px;
	letter-spacing: -0.403846px;
	padding-left: 15px;
	color: #FFFFFF;
}

#vintequartoh-inicio{
	font-family: TTSupermolotNeue-ExpandedExtraBoldItalic;
	font-size: 140px;
	line-height: 80px;
	letter-spacing: -2px;
	color: #FFFFFF;
}


.local-servicos{
	z-index: 1;
}

#painel-24-horas{
	background: var(--azul-escuro);
	border-radius: 3px;
	width: calc( var(--largura-pagina) - 200px );
	height: 390px;
	top:-100px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

#painel-24horas-tamanho{
	width: 900px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

#subir{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;	
	align-items: center;
	justify-content: center;
}

#texto-24horas{
	color:white;
	width: 300px;
	height: 144px;
	font-size: var(--font-tamanho);
	font-style: normal;
	font-weight: normal;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#painel-24-horas-conteudo{
	height: 204px;
	width: 500px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}


#painel-orcamento{
	top:-135px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#figura-orcamento{
	position: relative;
	left: -20px;
	width: 670px;
	height: 350px;
	background-image:url('../assets/images/inicio/teclado.png');
}

#texto-orcamento{
	color:black;
	width: 292px;
	height: 88px;
	font-size: var(--font-tamanho);
	font-style: normal;
	font-weight: normal;
	line-height: 22px;
	letter-spacing: -0.134615px;
}


#painel-orcamento-conteudo{
	padding-top: 30px;
	width: 292px;
	display: flex;
	flex-direction: column;
}

#titulo-orcamento{
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 47px;
}

.local-orcamento{

	/*position: relative;
	top:-30px;
	left: 0px;*/
}


#carousel-clientes{
	position: relative !important;
	top:-50px !important;
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	width: 780px !important;
	justify-content: space-around !important;
}

#carousel-item-id{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

#figura-seta-direita{
}

#figura-seta-direita:hover{
	cursor: pointer;
}

#figura-seta-esquerda{
}

#figura-seta-esquerda:hover{
	cursor: pointer;
}

#figura-seta-direita-c{
	display: none;
}

#figura-seta-direita-c:hover{
	cursor: pointer;
}

#figura-seta-esquerda-c{
	display: none;
}

#figura-seta-esquerda-c:hover{
	cursor: pointer;
}

#fala-clientes{
	height: 247px ;
	width: 380px ;
	background-color: var(--cinza-claro);
	display: flex ;
	flex-direction: row ;
	align-items: center ;
	justify-content: center;
	padding:20px ;
}

#fala-clientes-texto{
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: -0.211538px;
	padding-left: 10px;
}

#abreaspas{
	align-self: flex-start;
}

#fechaaspas{
	align-self: flex-end;
}

#cartao-clientes{
	display: flex !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: center !important;
	height: 100px !important;
	width: 162px !important;
}

#cartao-clientes-imagem{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 160px;
	height: 130px;
}

#nome-cliente{
	font-size: 14px;
	margin-top:-10px;
	padding: 0;
	word-wrap: normal;
}

#painel-video{
/*	position: relative;
	top:-150px;*/
	margin: 70px auto 0;
}

#video{

}


#painel-segura{
	margin: 70px auto 0;
}

#painel-segura-c{
	display: none;
}

#painel-rodape{
	margin: 70px auto 0;
	background-image:url('../assets/images/inicio/rodape.png');
	background-repeat: round;
	width: var(--largura-pagina);
	height: auto;
}

#inicio-rodape-img{

}

#inicio-rodape-img-c{
	display: none;
}

#rodape-conteudo-inicio{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;	
	padding: 100px;
}

#rodape-titulo-inicio{
	font-family: Nunito-ExtraBold;
	font-style: normal;
	font-weight: 800;
	font-size: 44px;
	line-height: 44px;
	color:white;
	width: 300px;
}

#rodape-textos-inicio{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

#imagem-rodape{
/*	position: relative;
	top:16px;*/
	z-index: -2;
}

#telefone-inicio,#rua-inicio,#bairro-inicio{
	color:white;
	margin-bottom: 7px;
	font-size: var(--font-tamanho);
}

#telefone-inicio{
	font-weight: bold;
}

.email-inicio{
	margin-top: 20px;
}

#div-right-btns{
	display: flex;
	flex-direction: column;	
	justify-content: flex-start;
	align-items: center;
	position:fixed;
	bottom:45%;
	right:0px;
	z-index:1;
}

#div-bottom-arrow{
	position:fixed;
	bottom:8%;
	right:2%;
	z-index:1;
	font-size: 40px;
}

#subir{
	color: var(--cinza-contorno);
}

#subir:hover{
	cursor: pointer;
}

#contorno-whats{
	width:35px;
	height:60px;
	color:#FFF;
	border-radius:8px 0 0 0;
	font-size:28px;
  	z-index:1;
  	display: flex;
	flex-direction: row;	
	justify-content: center;
	align-items: center;
}

#icone-contato{
	color: white;
}

#icone-whats{
	color: white;
}

#contorno-contato{
	width:35px;
	height:60px;
	color:#FFF;
	border-radius:0 0 0 8px;
	font-size:25px;
  	z-index:1;
  	display: flex;
	flex-direction: row;	
	justify-content: center;
	align-items: center;
}

#contorno-contato-c{
	width:35px;
	height:60px;
	color:#FFF;
	border-radius:0 0 0 8px;
	font-size:10px;
  	z-index:1;
  	display: flex;
	flex-direction: row;	
	justify-content: center;
	align-items: center;
}

#contorno-contato,#contorno-contato-c:hover{
	cursor: pointer;
}

#contorno-contato-c{
	display: none;
}

#contorno-label{
	width:100px;
	height:30px;
	color:#FFF;
	border-radius:0 0 10px 10px;
	font-size:17px;
  	z-index:1;
  	display: none;
	flex-direction: column;	
	justify-content: flex-start;
	align-items: center;
	background-color: var(--cinza);
	position:fixed;
	left: 5%;
}


#rodape{
	width: 100%;
	height: 100px;
	background-color: var(--cinza);
	display: flex;
	flex-direction: row;	
	align-items: center;
	justify-content: center;
}

#rodape p{
	color : grey;
}


@media only screen and (max-width:1024px) {
	#container-inicio{

	}
}

@media only screen and (max-width:768px) {

	#inicio{
		max-width: 450px;
		margin:0px auto;
		margin-bottom: -80px;
	}

	#painel-familia{
		background-image:url('../assets/images/inicio/familia-c.jpg');
		background-repeat: round;
		min-height: 600px;
		height: auto !important; 
		width: 100%;
		margin: 0;
		padding: 0;
	}
	#slogan-inicio{
		left:25px;
		font-size: 32px;
		line-height: 32px;
		width: 280px;
		letter-spacing: 0px;
		top:-40px;
	}

	#subir{
		position: relative;
		top:-180px;
	}
	#painel-familia-botao{
		position: none;
		left:50px;
		display: none;

	}

	#painel-24-horas{
		width: 90%;
		height: 400px;
		top:0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content:flex-start;
	}
	#monitoramento-inicio{
		font-size: 24px;
		padding:5px;
	}
	#vintequartoh-inicio{
		font-size: 110px;
		line-height: 35px;
	}
	#painel-24horas-tamanho{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-top: 15px;
	}
	#painel-24-horas-conteudo{
		width: 250px;
		height: 260px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content:center;
		padding-top: 0px;
	}
	.local-servicos{
		z-index: 1;
		margin-top: 10px !important;
	}
	#texto-24horas{
		width: 250px;
		display: flex;
		flex-direction: column;
		margin: 0px;
		text-align: left;
		margin: 0px auto 10px;
	}
	#botao-24horas-servicos{
		margin-top: 5px;
	}

	#painel-orcamento-conteudo{
		width: 250px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 0px;
		margin: 0;
	}
	#painel-orcamento{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		top:-20px;
	}
	#figura-orcamento{
		width: 100%;
		background-image:url('../assets/images/inicio/teclado-c.png');
		background-repeat: round;
		margin: 0;
		padding: 0;
		left: 0;
		min-height: 200px;
		height: auto;
	}

	#botao-orcamento{

	}

	#figura-orcamento-c img{

	}
	
	#titulo-orcamento{
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 30px;
		width: 250px;
	}
	#texto-orcamento{
		width: 250px;
		height: 88px;
		line-height: 22px;
		letter-spacing: -0.134615px;
	}
	.local-orcamento{	
	}

	#carousel-clientes{
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		width: 100% !important;
		margin-top: -80px !important;
		margin-bottom:  20px;
	}

	#carousel-item-id{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
	}

	#fala-clientes{
		width: 100% !important;
		height: 250px !important;
	}

	#abreaspas{
	}
	#fechaaspas{
	}

	#fala-clientes-texto{
		font-size: 14px;
		letter-spacing: -0.211538px;
	}
	#figura-seta-direita{
		display: none;
	}
	#figura-seta-esquerda{
		display: none;
	}
	#figura-seta-direita-c{
		display: block;
		margin-left: 20px;
	}
	#figura-seta-esquerda-c{
		display: block;
		margin-right: 20px;
	}

	#cartao-clientes{
		margin-top: 30px;
		justify-content: space-around;
		width: 300px !important;
	}

	#video{
		height: 313px;
		width: 300px;
	}

	#painel-video{
		top:-60px;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	#painel-segura{
		display: none;
	}

	#painel-segura-c{
		display: flex;
		margin: 0;
		padding: 0;
	}

	#inicio-rodape-img{
		display: none;
	}

	#inicio-rodape-img-c{
		display: block;
	}

	#painel-rodape{
		background-image:url('../assets/images/inicio/rodape-c.png');
		background-size: contain;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	#rodape-conteudo-inicio{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 100px 0px 100px 0px;
	}

	#rodape-titulo-inicio{
		font-size: 40px;
		line-height: 40px;	
		width: 250px;
		padding-left: 15px;
	}

	#rodape-textos-inicio{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 15px;
	}

	.menu-nav{
		display: none;
	}

	#imagem-rodape{
	}

	#contorno-contato-c{
		display: flex;
	}

	#contorno-contato{
		display: none;
	}

	#contorno-whats{
		width: 30px;
		height:50px;
		font-size:20px;
	}

	#contorno-contato,#contorno-contato-c{
		width: 30px;
		height:50px;
		font-size:18px;
	}

	#contorno-label{
		display: none;
	}

	#icone-whats{
	}

	#div-bottom-arrow{
		display: none;
	}


}

@media only screen and (max-width:414px) {	
	#painel-familia{
		min-height: 610px;
	}
}
